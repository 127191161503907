<template>
  <div>
    <template v-if="!loading.overall">
      <v-container>
        <!-- Settings Gear, no Gear -->
        <SettingsGear :showIcon="false" />
        <!-- One Logo / Slogan -->
        <div class="one-logo">
          <div class="one-logo-content">
            <img src="@/assets/logos/dime_logo_gold.png" />
          </div>
        </div>
        <!-- Content -->
        <div class="content">
          <div class="content-logo">
            <!-- <img src="@/assets/logos/dime_thinkhigher.png" /> -->
          </div>
          <!-- Login -->
          <div class="content-form">
            <form style="width: 100%" @submit.prevent>
              <div class="mb-3">
                <input
                  ref="EMAIL"
                  type="text"
                  :style="`border: 2px solid ${getAccentColor};`"
                  placeholder="EMAIL"
                  v-model.trim="payload.user.email"
                  class="form-control py-2 form-input"
                  autocomplete="on"
                  maxlength="128"
                />
              </div>
              <template>
                <div class="mb-5">
                  <!-- <button
                    class="text-white py-2 form-button"
                    :style="`background-color: ${
                      validSubmission ? 'grey' : getAccentColor
                    };`"
                    @click="traverse(loginMode)"
                    :disabled="validSubmission"
                  > -->
                  <button
                    class="text-white py-2 form-button"
                    :style="`background-color: ${getAccentColor};`"
                    @click="traverse(loginMode)"
                    :disabled="buttonDisabled"
                  >
                    <span class="form-span">get verify code </span>
                  </button>
                  <div
                    v-show="!currentLoginMode"
                    class="text-white text-center py-2 text-uppercase"
                    style="margin-top: 8px"
                    @click="onClickForgot"
                  >
                    FORGOT EMAIL/PASSWORD?
                  </div>
                </div>
              </template>
            </form>
          </div>
        </div>
        <!-- Footer -->

        <div
          style="
            display: flex;
            height: 100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding-bottom: 4vh;
          "
        >
          <button
            @click="traverse('back')"
            class="text-white py-2 form-button register-back-img"
            style="
              padding-bottom: 1vh;
              background-color: #ffab00;
              margin: 0 3vw;
            "
          >
            <span class="form-span">return</span>
          </button>
          <img
            style="height: 3.5em; margin-top: 3vh"
            src="@/assets/logos/one_logo_black.png"
          />
        </div>

        <!-- <div style="height: 10vh; text-align: center">
          <div
            style="
              height: 100%;
              display: flex;
              justify-content: center;
              align-items: flex-start;
            "
          >
            <div class="row">
              <div
                class="col form-col"
                @click="
                  currentLoginMode = false;
                  traverse('switchLoginMode');
                "
              >
                <span
                  class="text-uppercase"
                  :style="`color: ${
                    currentLoginMode ? '#fff' : getActionColor
                  };`"
                  >login</span
                >
              </div>
              <div class="col">
                <label class="switch" @change="traverse('switchLoginMode')">
                  <input type="checkbox" v-model="currentLoginMode" />
                  <span class="slider round"></span>
                </label>
              </div>
              <div
                class="col form-col"
                @click="
                  currentLoginMode = true;
                  traverse('switchLoginMode');
                "
              >
                <span
                  class="text-uppercase"
                  :style="`color: ${
                    currentLoginMode ? getActionColor : '#fff'
                  };`"
                  >new user</span
                >
              </div>
            </div>
          </div>
        </div> -->
      </v-container>
    </template>
    <template v-else> Loading...</template>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Toast } from "vant";

export default {
  name: "getCode",
  data() {
    return {
      loading: {
        overall: true,
      },
      loginMode: "register", //登录 login OR 注册 register
      currentLoginMode: true, // 获取验证码
      response: {},
      payload: {
        user: {
          email: "",
          password: "",
        },
      },
    };
  },
  created() {
    this.setup();
  },

  methods: {
    ...mapActions([
      "_setLoginState",
      "_setLoginInfo",
      "_setRegisterEmail",
      "_setPresetInfo",
    ]),
    /* API Calls */
    // GET
    // POST
    /* Main */
    async setup() {
      if (window.localStorage.getItem("front-token")) {
        this.$router.replace({
          name: "device",
        });
        this._setLoginInfo(window.localStorage.getItem("userInfo"));
      } else {
        await this.setLoadingState(true);
        this._setRegisterEmail("");
        // //this.payload.user.email = this.payload.user.password = "";
        // // Methods
        await this.setLoadingState(false);
      }
    },
    traverse(action) {
      console.log(action);
      switch (action) {
        // 发送验证码
        case "register":
          if (this.payload.user.email.length == 0) {
            this.$showTips({ msg: "Input an e-mail" });
            // Toast.fail("Input an e-mail");
            this.$refs.EMAIL.focus();
            return;
          }
          if (!this.emailSet) {
            this.$showTips({ msg: "E-mail incorrectly formatted" });
            // Toast.fail("E-mail incorrectly formatted");
            this.$refs.EMAIL.focus();
            return;
          }
          this.showLoading("Sending...");
          this.$api.user
            .sendEmail({ email: this.payload.user.email })
            .then((res) => {
              this.hideLoading();
              if (res.data.code === 200) {
                this._setRegisterEmail(this.payload.user.email);
                // Toast.success(res.data.message);
                this.$showTips({
                  msg: res.data.message,
                  duration: 3000,
                  showType: "info",
                });
                this.$router.push({
                  name: "verify_code",
                  params: {
                    type: "forgot", //找回密码
                    email: this.payload.user.email,
                  },
                });
              } else {
                this.$showTips({ msg: res.data.message });
                // Toast.fail(res.data.message);
              }
            })
            .catch((res) => {
              this.hideLoading();
              this.$showTips({ msg: res.message });
              // Toast.fail(res.message);
            });
          break;
        // 切换登录方式
        case "switchLoginMode":
          if (!this.currentLoginMode) {
            this.loginMode = "login";
          } else {
            this.loginMode = "register";
          }
          break;
        case "back":
          this.$router.back();
          break;
      }
    },
    /* Misc */
    async setLoadingState(loadingState) {
      this.loading.overall = loadingState;
    },
    onClickForgot() {},
  },
  computed: {
    ...mapGetters([
      "getMainColor",
      "getAccentColor",
      "getLoginState",
      "getActionColor",
    ]),
    buttonDisabled() {
      if (this.payload.user.email.length == 0) {
        return true;
      } else {
        return false;
      }
    },
    emailSet() {
      var reg = /^([a-zA-Z]|[0-9]|\.|\-|\_)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/;
      return reg.test(this.payload.user.email) ? true : false;
    },
    passwordSet() {
      return this.payload.user.password.length >= 6 &&
        this.payload.user.password.length <= 20
        ? true
        : false;
    },
    validUserLogin() {
      return this.emailSet && this.passwordSet ? true : false;
    },
    validRegister() {
      return this.emailSet ? true : false;
    },
    validSubmission() {
      return !this.currentLoginMode
        ? !this.validUserLogin
        : !this.validRegister;
    },
  },
};
</script>

<style lang="less" scoped>
::placeholder {
  color: white;
  text-align: center;
}

.one-logo {
  height: 15vh;

  .one-logo-content {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;

    img {
      height: 7em;
    }
  }
}

.content {
  height: 58vh;

  .content-logo {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: flex-end;
    height: 6.5em;

    img {
      height: 2.5em;
    }

    .content-form {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      height: 100%;
    }
  }
}

.form-input {
  background-color: transparent;
  color: white;
  border-radius: 30px;
}

.form-button {
  border: none;
  width: 100%;
  border-radius: 30px;
  font-weight: 500;
}

.form-button:disabled {
  background: #959595 !important;
}

.form-span {
  text-transform: uppercase;
  font-size: 1.2em;
}

.form-col {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  border: 2px solid #ffab00;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  left: 3px;
  bottom: 3px;
  background-color: #ffab00;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ffab00;
}

input:checked + .slider:before {
  -webkit-transform: translateX(27px);
  -ms-transform: translateX(27px);
  transform: translateX(27px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.register-back-img {
  background-position: 2vw;
  background-image: url("../../assets/ui/register-back.svg");
}
</style>
